<template>
  <div
    class="modal card"
    style="z-index: 1006 !important"
    v-bind:class="{ 'is-active': showZoneListModal }"
  >
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body notification">
        <button class="delete" aria-label="close" @click="toggleClose">
          Close
        </button>
        <h3 class="title is-6 has-text-success">Zones</h3>
        <h6 class="subtitle has-text-warning is-7 has-text-success">
          If you are using our billing module, these zones will connect
          automatically to your rate tables.
        </h6>
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Zone</th>
              <th>Environment</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="zone in zoneList" :key="zone.value">
              <th v-bind:style="{ color: zone.color }">{{ zone.value }}</th>
              <th>{{ zone.environment }}</th>
              <td>
                <button
                  class="button is-small is-success level-item"
                  @click="openEditModal(zone)"
                >
                  Edit
                </button>
              </td>
              <td>
                <button
                  class="button is-small is-warning level-item"
                  @click="openDeleteModal(zone)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
        <div class="columns is-gapless">
          <div class="column mx-1">
            <input type="text" v-model="newZone.value" placeholder="Zone" />
          </div>
        </div>
        <footer class="card-footer">
          <button
            class="button is-success is-fullwidth is-small"
            v-bind:class="isAdding ? addButtonLoading : addButtonNotLoading"
            @click="addZone"
          >
            Add
          </button>
        </footer>
      </section>
    </div>
    <EditZoneListModal
      v-if="showEditModal"
      @renderMap="toggleRender"
      :showEditModal="showEditModal"
      :selectedZone="selectedZone"
      :renderMap="renderMap"
      @closeModal="closeEditModal"
    />
    <DeleteZoneListModal
      v-if="showDeleteModal"
      :showDeleteModal="showDeleteModal"
      :selectedZone="selectedZone"
      @closeModal="closeDeleteModal"
    />
  </div>
</template>
<script>
import { insertZoneInfo } from "@/store/fireStore";
import store from "../../store";

const environment = store.state.environment;

export default {
  components: {
    EditZoneListModal: () => import("@/components/Zone/ZoneListEditModal"),
    DeleteZoneListModal: () => import("@/components/Zone/ZoneListDeleteModal"),
  },
  data() {
    return {
      isAdding: false,
      addButtonLoading: "button is-small is-info level-item  is-loading",
      addButtonNotLoading: "button is-small is-info level-item ",
      showEditModal: false,
      showDeleteModal: false,
      selectedZone: null,
      newZone: {
        value: "",
      },
    };
  },
  props: {
    showZoneListModal: {
      type: Boolean,
    },
  },
  methods: {
    toggleClose() {
      this.$emit("closeModal");
    },
    async toggleRender() {
      await this.$emit("renderMap");
    },
    async addZone() {
      const randomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      };
      this.isAdding = true;
      if (this.newZone.value === "") {
        this.isAdding = false;
        this.$notify({
          title: "Please fill all fields",
          type: "error",
          group: "zones",
        });
        return;
      }
      console.log("environment :>> ", environment.toLocaleLowerCase());
      let zoneInfo = {};

      if (environment.toLocaleLowerCase() === "logistics") {
        zoneInfo = {
          BaseCharge: 0,
          DeliverySurcharge: 0,
          UnitCharge: 0,
          ProductSurcharge: 0,
          MaximumCharge: 0,
          min: 0,
          max: 0,
          color: randomColor(),
          value: this.newZone.value,
          selected: false,
          environment: environment.toLocaleLowerCase(),
        };
      } else if (environment.toLocaleLowerCase() === "installation") {
        zoneInfo = {
          SimpleInstallation: "",
          ComplexInstallation: "",
          InstTripCharge: "",
          min: 0,
          max: 0,
          color: randomColor(),
          value: this.newZone.value,
          selected: false,
          environment: environment.toLocaleLowerCase(),
        };
      } else if (environment.toLocaleLowerCase() === "mieleprofessionals") {
        zoneInfo = {
          Province: this.$store.state.region,
          color: randomColor(),
          environment: "mieleprofessionals",
          fsa: "",
          shared: false,
          value: this.newZone.value,
        };
      } else {
        zoneInfo = {
          Province: this.$store.state.region,
          Technicians: {
            [this.newZone.value]: true,
          },
          color: randomColor(),
          environment: "services",
          fsa: "",
          shared: false,
          value: this.newZone.value,
        };
      }

      await insertZoneInfo(zoneInfo)
        .then((res) => {
          this.isAdding = false;
          this.$notify({
            type: "success",
            group: "zones",
            title: "Zone Added Successfully",
          });
          this.newZone = {
            value: "",
          };
        })
        .catch((e) => {
          console.log("e :>> ", e);
          this.$notify({
            type: "error",
            group: "zones",
            title: "There was an error, please try again",
          });
        });
    },
    openEditModal(zone) {
      this.showEditModal = true;
      this.selectedZone = zone;
    },
    closeEditModal() {
      this.showEditModal = false;
      this.selectedZone = null;
    },
    openDeleteModal(zone) {
      this.showDeleteModal = true;
      this.selectedZone = zone;
    },
    closeDeleteModal() {
      this.showDeleteModal = false;
      this.selectedZone = null;
    },
    renderMap() {
      this.$emit("renderMap");
    },
  },
  computed: {
    zoneList() {
      return this.$store.state.zoneInformations;
    },
    currentRegion() {
      return store.state.region;
    },
  },
};
</script>
<style></style>
