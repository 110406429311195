var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal card",
      class: { "is-active": _vm.showZoneListModal },
      staticStyle: { "z-index": "1006 !important" },
    },
    [
      _c("div", { staticClass: "modal-background" }),
      _c("div", { staticClass: "modal-card" }, [
        _c("section", { staticClass: "modal-card-body notification" }, [
          _c(
            "button",
            {
              staticClass: "delete",
              attrs: { "aria-label": "close" },
              on: { click: _vm.toggleClose },
            },
            [_vm._v(" Close ")]
          ),
          _c("h3", { staticClass: "title is-6 has-text-success" }, [
            _vm._v("Zones"),
          ]),
          _c(
            "h6",
            { staticClass: "subtitle has-text-warning is-7 has-text-success" },
            [
              _vm._v(
                " If you are using our billing module, these zones will connect automatically to your rate tables. "
              ),
            ]
          ),
          _c("table", { staticClass: "table is-fullwidth" }, [
            _vm._m(0),
            _c(
              "tbody",
              _vm._l(_vm.zoneList, function (zone) {
                return _c("tr", { key: zone.value }, [
                  _c("th", { style: { color: zone.color } }, [
                    _vm._v(_vm._s(zone.value)),
                  ]),
                  _c("th", [_vm._v(_vm._s(zone.environment))]),
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "button is-small is-success level-item",
                        on: {
                          click: function ($event) {
                            return _vm.openEditModal(zone)
                          },
                        },
                      },
                      [_vm._v(" Edit ")]
                    ),
                  ]),
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "button is-small is-warning level-item",
                        on: {
                          click: function ($event) {
                            return _vm.openDeleteModal(zone)
                          },
                        },
                      },
                      [_vm._v(" Remove ")]
                    ),
                  ]),
                ])
              }),
              0
            ),
            _c("tfoot"),
          ]),
          _c("div", { staticClass: "columns is-gapless" }, [
            _c("div", { staticClass: "column mx-1" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newZone.value,
                    expression: "newZone.value",
                  },
                ],
                attrs: { type: "text", placeholder: "Zone" },
                domProps: { value: _vm.newZone.value },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.newZone, "value", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _c("footer", { staticClass: "card-footer" }, [
            _c(
              "button",
              {
                staticClass: "button is-success is-fullwidth is-small",
                class: _vm.isAdding
                  ? _vm.addButtonLoading
                  : _vm.addButtonNotLoading,
                on: { click: _vm.addZone },
              },
              [_vm._v(" Add ")]
            ),
          ]),
        ]),
      ]),
      _vm.showEditModal
        ? _c("EditZoneListModal", {
            attrs: {
              showEditModal: _vm.showEditModal,
              selectedZone: _vm.selectedZone,
              renderMap: _vm.renderMap,
            },
            on: { renderMap: _vm.toggleRender, closeModal: _vm.closeEditModal },
          })
        : _vm._e(),
      _vm.showDeleteModal
        ? _c("DeleteZoneListModal", {
            attrs: {
              showDeleteModal: _vm.showDeleteModal,
              selectedZone: _vm.selectedZone,
            },
            on: { closeModal: _vm.closeDeleteModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("Zone")]), _c("th", [_vm._v("Environment")])]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }